import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo, FAQJsonLd  } from 'gatsby-plugin-next-seo';
export default ({ data }) => {
  const { page } = data
  const { title, content, databaseId, seo, slug } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={seo.title} description={seo.metaDesc} 
        canonical={`https://www.kasinokukko.com/${slug}/`}
      />
      {title === "Nettikasinot" &&
        <FAQJsonLd
        questions={[
          { question: 'Voiko nettikasinolta voittaa?', answer: 'Kyllä voi. Nettikasinot antavat ilman muuta mahdollisuuden voitoille ja parhaassa tilanteessa ne saattavat kohota todella suuriin lukemiin. On kuitenkin mainittava, että jokaisella pelikerralla onni ei välttämättä osu kohdalle.' },
          { question: 'Saako suomalaiset pelata nettikasinoilla?', answer: 'Kyllä vain! Nettikasinot ovat täysin laillisia ja suomalaiset saavat pelata niillä niin paljon kuin vain haluavat. Voit siis valita täältä minkä tahansa pelipaikan ja lähteä jahtaamaan suuria voittoja erilaisten etujen kera!' },
          { question: 'Mistä löydän luotettavat ja turvalliset nettikasinot?', answer: 'Tänä päivänä melkein jokainen netissä oleva pelipaikka on täysin luotettava ja turvallinen. Jos kuitenkin haluat olla 100 prosenttisesti varma asiasta, pelaa vain sivujemme kautta löytyvillä nettikasinoilla. Jokainen sivusto on tarkastettu, että ne sopivat suomalaisille.' },
          { question: 'Tarjoavatko nettikasinot aina verovapaat voitot?', answer: 'Valitettavasti ei. Nettikasinon tulee toimia ETA-maan pelilisenssillä, että voitot ovat verovapaita suomalaisille pelaajille. Onneksi casinot omaavat kuitenkin Maltan tai Viron lisenssin, joka takaa verottomat voitot. Tämä kannattaa kuitenkin aina tsekata varmuuden vuoksi.' },
          { question: 'Ovatko nettikasinot avoinna ympäri vuorokauden?', answer: 'Kyllä ovat! Nettikasinot tarjoavat palveluitaan aina seitsemän päivää viikosta ja vielä ympäri vuorokauden. Voit siis lähteä pelaamaan milloin tahansa slotteja casinolle. Nykyään useat pelipaikat maksavat kotiutukset tilille muutamassa minuutissa, vaikka tekisit siirron keskellä yötä.' },
        ]}
      />
      }
      {title === "Casinobonukset" &&
        <FAQJsonLd
        questions={[
          { question: 'Millaiset casinobonukset kannattaa lunastaa?', answer: 'Paras neuvo meiltä on napata talteen ne casinobonukset, jotka ovat helpolla kierrätyksellä. Näin saat paremmat mahdollisuudet suorittaa kierrätysvaatimuksen loppuun. Totta kai kierrätysvapaat bonukset ovat myös oiva valinta.' },
          { question: 'Saako casinobonuksia ilman talletusta?', answer: 'Kyllä vain! Casinobonukset eivät aina vaadi talletusta, joka on tietenkin todella mukava lisä nettipelien maailmaan. Voit napata nämä talletusvapaat edut aina sivujemme kautta. Päivitämme tarjouksia aina viikoittain!' },
          { question: 'Miten casinobonus kannattaa kierrättää?', answer: 'Jos pelitilisi saldo on todella suuri ja haluat saada tehtyä mahdollisimman ison kotiutuksen, pelaa pienen volatiliteetin slotteja. Nämä kyseiset pelit antavat jatkuvasti voittoja, eli tyhjiä pelikierroksia ei tule putkeen. Jos taas tarvitset ison voiton, testaa onneasi korkean volatiliteetin pelissä.' },
          { question: 'Mistä saan parhaat casinobonukset?', answer: 'Jokaisen pitäisi tietää tähän vastaus. Parhaat casinobonukset löytyvät tietenkin täältä. Olemme koonneet sivuillemme markkinoiden parhaat tarjoukset, jotka pääset lunastamaan näppärästi napin painalluksella. Tsekkaa siis mahtavat edut jo tänään!' },
          { question: 'Kuinka isoja casinobonuksia voi saada?', answer: 'Nykyään netistä on mahdollista lunastaa todella isot casinobonukset tilille. Sivujemme kautta löydät muun muassa 2 000 euron arvoiset edut sekä 500% talletusbonarit. Voit siis lunastaa täältä bonuksia laidasta laitaan.' },
        ]}
      />
      }
      {title === "Uudet kasinot 2021" &&
        <FAQJsonLd
        questions={[
          { question: 'Mistä löydän parhaat uudet kasinot?', answer: 'Kaikki vuoden 2021 uudet kasinot ovat listattuna tänne. Mikäli siis haluat testata tuliterän kasinon mahtavien etujen kera, kannattaa tarkastaa sivuiltamme löytyvät pelipaikat. Listaa päivitetään jatkuvasti ja jokainen sivuiltamme löytyvä casino sopii suomalaisille pelaajille. Voit siis huoletta lähteä testaamaan mitä tahansa valitsemaasi yksilöä!' },
          { question: 'Kuinka tunnistan hyvät uudet kasinot?', answer: 'Parhaat pelipaikat voi tunnistaa useasta eri asiasta, kuten pelilisenssista, verkkosivujen kielestä ja pelitarjonnasta. Hyvät uudet kasinot toimivat esimerkiksi Maltan tai Viron lisenssillä, jonka lisäksi ne ovat käännetty suomen kielelle ja tarjoavat vähintään 1 500 kappaletta pelejä.' },
          { question: 'Miksi liittyisin uudelle kasinolle?', answer: 'Yleisesti uudet kasinot 2021 tarjoavat pelaajille mahtavat bonuspaketit. Jos siis haluat uuden pelikokemuksen sekä hyvät tervetulotarjoukset tilille, kannattaa laittaa tuliterät pelipaikat testiin. Totta kai pääset myös pyörittämään slotteja sivustolla, joka toimii uusimmalla teknologialla sekä alustalla.' },
          { question: 'Ovatko uudet kasinot 2021 turvallisia?', answer: 'Tänä päivänä lähes jokainen netistä löytyvä pelipaikka on 100% turvallinen ja luotettava. Tästä saamme kiittää peliviranomaisia, jotka tarkkailevat jatkuvasti sivuston toimintaa. Näin uudet kasinot eivät pysty harrastamaan mitään epärehellistä toimintaa. Kaikki sivuiltamme löytyvät casinot ovat lisensoitu ja todettu turvalliseksi.' },
          { question: 'Antavatko uudet kasinot parempia etuja?', answer: 'Tervetulotarjoukset ja muut kampanjat paranevat vuosi vuodelta, joka on ilman muuta ainoastaan plussaa meille pelaajille. Bonarit ja muut edut vaihtelevat todella paljon, eli vanhat sivustot saattavat tarjota vielä tänäkin päivänä paremmat kampanjat. Pääset kuitenkin tarkastamaan sivujemme kautta kaikki uudet kasinot ja hyvät uusien pelaajien edut.' },
        ]}
      />
      }
      <article
        className={`post-${databaseId} post page type-page status-publish hentry testi`}
        id={`post-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </header>
        <div className="post-inner thin container">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      seo {
        title
        metaDesc
      }
      slug
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
